const eventPageLeadData = {
  leadPage: 'Мероприятие',
  leadAction: 'Регистрация на мероприятие',
};

const mainData = {
  leadPage: 'События',
};

const data = {
  mkIntroduce: {
    beginners: {
      JS: {
        list: [
          'Основы JS от присвоения переменных до написания несложных функций',
          'Максимум работы с кодом и минимум скучной теории',
          'Решите реальные задачи от члена приемной комиссии Эльбруса',
          'Попробуете свои силы и поймете, насколько вам подходит направление',
          'Зададите вопросы действующему специалисту',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
      DS: {
        list: [
          'Решение задачек по Python',
          'Максимум работы с кодом и минимум скучной теории',
          'Общение с действующим Data Scientist-ом и ответы на ваши вопросы',
          'Нарастите знания к поступлению в Эльбрус Буткемп',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
    },
    advanced: {
      JS: {
        list: [
          'Основы JS от присвоения переменных до написания несложных функций',
          'Максимум работы с кодом и минимум скучной теории',
          'Решите реальные задачи от члена приемной комиссии Эльбруса',
          'Попробуете свои силы и поймете, насколько вам подходит направление',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
      DS: {
        list: [
          'Решение задачек по Python',
          'Максимум работы с кодом и минимум скучной теории',
          'Общение с действующим Data Scientist-ом и ответы на ваши вопросы',
          'Нарастите знания к поступлению в Эльбрус Буткемп',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
    },
  },
  questions: {
    message: 'Остались вопросы?',
    actions: {
      first: {
        title: 'Получить консультацию',
      },
      second: {
        title: 'Посмотреть Программы',
        link: '/#program',
      },
    },
  },
  seo: {
    title:
      'Бесплатные мероприятия и встречи Эльбрус Буткемп в режиме online и offline',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Эльбрус Буткемп организует мероприятия для программистов и тех, кто только начинает свой путь в IT-индустрии. Узнайте о наших конференциях, встречах и других мероприятиях перейдя по ссылке.',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Бесплатные мероприятия и встречи Эльбрус Буткемп в режиме online и offline',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Эльбрус Буткемп организует мероприятия для программистов и тех, кто только начинает свой путь в IT-индустрии. Узнайте о наших конференциях, встречах и других мероприятиях перейдя по ссылке.',
      },
      {
        property: 'og:image',
        name: null,
        content:
          'https://elbrusboot.camp/static/59a06fca46dc5338df6539b8816fd316/f85ce/slide-4.jpg',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: '@elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content:
          'https://elbrusboot.camp/static/59a06fca46dc5338df6539b8816fd316/f85ce/slide-4.jpg',
      },
    ],
  },
  leadData: {
    question: {
      ...mainData,
      eventName: 'Оставьте ваши контакты для связи',
      leadAction: 'Получить консультацию',
      leadBlock: 'Остались вопросы',
    },
    subscribe: {
      ...mainData,
      eventName: 'Анонсы событий',
      leadAction: 'Подписка новости',
      leadBlock: 'Анонсы событий',
    },
    events: {
      ...mainData,
      leadAction: 'Регистрация на мероприятие',
      leadBlock: 'Бесплатные мероприятия',
    },
  },
  motivate: {
    title: 'Стать программистом может каждый!',
    subtitle: 'ВАШИ СОМНЕНИЯ И МЫСЛИ — НАШИ ОТВЕТЫ',
    research: {
      title: 'Исследование hh.ru',
      content:
        'Статистика по трудоустройству выпускников Эльбрус Буткемп подтверждена исследователями hh.ru',
      link: {
        title: 'Читать исследование',
        url: '/job-report2023/',
      },
    },
    chat: [
      {
        isAnswer: false,
        text: 'У меня ничего не получится, я — гуманитарий',
        sticker: 'chat/sticker1',
      },
      {
        isAnswer: true,
        text: 'У нас выпускаются люди разных профессий и складов ума',
        sticker: 'chat/sticker2',
      },
      {
        isAnswer: false,
        text: 'Учиться в буткемпе слишком дорого!',
        sticker: 'chat/sticker3',
      },
      {
        isAnswer: true,
        text: 'Лучшая инвестиция — это инвестиция в себя! Вы можете разбить большую сумму на небольшие комфортные платежи с помощью выгодной рассрочки.',
        sticker: 'chat/sticker4',
      },
      {
        isAnswer: false,
        text: 'После 40 сложно найти работу в IT',
        sticker: 'chat/sticker5',
      },
      {
        isAnswer: true,
        text: 'В IT смотрят на навыки, а не на возраст',
        sticker: 'chat/sticker6',
      },
    ],
  },
  reviews: {
    title: 'Отзывы',
    list: [
      {
        video: 'ACYxQi32Tbw',
        ratio: '9/16',
      },
      {
        video: '7zFmF4KROME',
        ratio: '9/16',
      },
      {
        video: 'zYeBdh9SDJw',
        ratio: '9/16',
      },
    ],
  },
  youWillKnow: {
    blockTitle: 'Вы узнаете:',
    list: [
      {
        period: 'Что такое Data Science?',
        width: '312px',
        description:
          'Объясним, чем занимаются дата сайентисты и какие направления в сфере существуют',
        backgroundColor: 'kit.background.lightpurple',
        withCircleIcon: true,
      },
      {
        period: 'Чему мы учим?',
        width: '312px',
        description:
          'Покажем подробную программу курса в Эльбрус Буткемп, ответим на ваши вопросы',
        backgroundColor: 'kit.background.lightpurple',
        withCircleIcon: true,
      },
      {
        period: 'Как поступить в Эльбрус Буткемп?',
        width: '312px',
        description:
          'Расскажем, как подготовиться к поступлению и какой бэкграунд нужен',
        backgroundColor: 'kit.background.lightpurple',
        withCircleIcon: true,
      },
      {
        period: 'Что с трудоустройством?',
        width: '312px',
        description:
          'Разберём рынок IT сейчас и вакансии, на которые претендуют выпускники буткемпа',
        backgroundColor: 'kit.background.lightpurple',
        withCircleIcon: true,
      },
    ],
  },
  statisticBlock: {
    title: 'Статистика',
    description: 'Мы гордимся нашими цифрами и результатами наших студентов',
    statistic: [
      {
        id: 1,
        value: '--',
        description: 'студентов выпущено...',
        link: null,
      },
      {
        id: 2,
        value: '--',
        description: 'студентов находят работу..',
        link: null,
      },
      {
        id: 8,
        value: '--',
        description: 'средняя зарплата после обучения',
        link: null,
      },
      {
        id: 4,
        value: '--',
        description: 'рекомендуют обучение в Эльбрус',
      },
    ],
  },
};

export default data;

export const mkOnlineData = {
  mkOnlineIntroduce: {
    requestLeadData: {
      ...eventPageLeadData,
      leadBlock: 'Топ',
    },
    beginners: {
      JS: {
        list: [
          'Основы JS от присвоения переменных до написания несложных функций',
          'Максимум работы с кодом и минимум скучной теории',
          'Решите реальные задачи от члена приемной комиссии Эльбруса',
          'Попробуете свои силы и поймете, насколько вам подходит направление',
          'Зададите вопросы действующему специалисту',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
      DS: {
        list: [
          'Решение задачек по Python',
          'Максимум работы с кодом и минимум скучной теории',
          'Возможность пообщаться с действующим Data Scientist-ом и задать вопросы по профессии',
          'Нарастите знания к поступлению в Эльбрус Буткемп',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
    },
    advanced: {
      JS: {
        list: [
          'Основы JS от присвоения переменных до написания несложных функций',
          'Максимум работы с кодом и минимум скучной теории',
          'Решите реальные задачи от члена приемной комиссии Эльбруса',
          'Попробуете свои силы и поймете, насколько вам подходит направление',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
      DS: {
        list: [
          'Решение задачек по Python',
          'Максимум работы с кодом и минимум скучной теории',
          'Возможность пообщаться с действующим Data Scientist-ом и задать вопросы по профессии',
          'Нарастите знания к поступлению в Эльбрус Буткемп',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
    },
  },
  canBeInterest: {
    title: 'Может быть интересно',
    description:
      'Мастер-классы, вебинары и дни открытых дверей в других форматах',
    leadData: {
      leadPage: 'Мероприятие',
      eventName: 'Анонсы событий',
      leadAction: 'Подписка новости',
      leadBlock: 'Анонсы событий',
      pipelineType: 'subscribeNew',
    },
  },
};

export const mkOfflineData = {
  mkOfflineIntroduce: {
    requestLeadData: {
      ...eventPageLeadData,
      leadBlock: 'Топ',
    },
    beginners: {
      JS: {
        list: [
          'Основы JS от присвоения переменных до написания несложных функций',
          'Максимум работы с кодом и минимум скучной теории',
          'Решите реальные задачи от члена приемной комиссии Эльбруса',
          'Попробуете свои силы и поймете, насколько вам подходит направление',
          'Зададите вопросы действующему специалисту',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
      DS: {
        list: [
          'Решение задачек по Python',
          'Максимум работы с кодом и минимум скучной теории',
          'Возможность пообщаться с действующим Data Scientist-ом и задать вопросы по профессии',
          'Нарастите знания к поступлению в Эльбрус Буткемп',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
    },
    advanced: {
      JS: {
        list: [
          'Основы JS от присвоения переменных до написания несложных функций',
          'Максимум работы с кодом и минимум скучной теории',
          'Решите реальные задачи от члена приемной комиссии Эльбруса',
          'Попробуете свои силы и поймете, насколько вам подходит направление',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
      DS: {
        list: [
          'Решение задачек по Python',
          'Максимум работы с кодом и минимум скучной теории',
          'Возможность пообщаться с действующим Data Scientist-ом и задать вопросы по профессии',
          'Нарастите знания к поступлению в Эльбрус Буткемп',
          'Занятие практическое, вам понадобится ноутбук',
        ],
      },
    },
  },

  canBeInterest: {
    title: 'Может быть интересно',
    description:
      'Мастер-классы, вебинары и дни открытых дверей в других форматах',
    leadData: {
      leadPage: 'Мероприятие',
      eventName: 'Анонсы событий',
      leadAction: 'Подписка новости',
      leadBlock: 'Анонсы событий',
      pipelineType: 'subscribeNew',
    },
  },
};

export const dodOnlineData = {
  dodOnlineIntroduce: {
    JS: {
      talk: {
        title: 'Общение с выпускником',
        list: [
          'его личный путь трудоустройства в IT с нуля',
          'тренды IT-сферы: карьера, зарплаты, компании',
          'Q&A с действующим программистом крупной компании',
        ],
      },
      presentation: {
        title: 'Презентация от куратора',
        list: [
          'про формат буткемпа и подробную программу обучения',
          'о поступлении и техническом интервью: материалы, задачи',
          'о преподавателях, кураторах, одногруппниках',
        ],
      },
      ymEvents: ['JSDodOnlineTopSignUp'],
    },
    DS: {
      talk: {
        title: 'Общение с выпускником',
        list: [
          'его личный путь трудоустройства в IT с нуля',
          'тренды IT-сферы: карьера, зарплаты, компании',
          'Q&A с действующим Data Scientist-ом крупной компании',
        ],
      },
      presentation: {
        title: 'Презентация от куратора',
        list: [
          'про формат буткемпа и подробную программу обучения',
          'о поступлении и техническом интервью: материалы, задачи',
          'о преподавателях, кураторах, одногруппниках',
        ],
      },
      ymEvents: ['DSDodOnlineTopSignUp'],
    },
    DESIGN: {
      talk: {
        title: 'Общение с выпускником',
        list: [
          'его личный путь трудоустройства в IT с нуля',
          'тренды IT-сферы: карьера, зарплаты, компании',
          'Q&A с действующим Data Scientist-ом крупной компании',
        ],
      },
      presentation: {
        title: 'Презентация от куратора',
        list: [
          'про формат буткемпа и подробную программу обучения',
          'о поступлении и техническом интервью: материалы, задачи',
          'о преподавателях, кураторах, одногруппниках',
        ],
      },
      ymEvents: ['DSDodOnlineTopSignUp'],
    },
  },

  canBeInterest: {
    title: 'Может быть интересно',
    description:
      'Мастер-классы, вебинары и дни открытых дверей в других форматах',
    leadData: {
      leadPage: 'Мероприятие',
      eventName: 'Анонсы событий',
      leadAction: 'Подписка новости',
      leadBlock: 'Анонсы событий',
      pipelineType: 'subscribeNew',
    },
  },
  ymEvents: {
    JS: ['JSDodOnlineFlyMod'],
    DS: ['DSDodOnlineFlyMod'],
  },
};
