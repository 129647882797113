import { TipIcon } from '../../../kit/icons/TipIcon';
import { CehIcon } from '../../../kit/icons/CehIcon';
import { VedomostiIcon } from '../../../kit/icons/VedomostiIcon';
import { TprogerIcon } from '../../../kit/icons/TprogerIcon';
import { VcIcon } from '../../../kit/icons/VcIcon';
import { SchoolIcon } from '../../../kit/icons/SchoolIcon';
import { RusbaseIcon } from '../../../kit/icons/RusbaseIcon';
import { YouTubeIcon } from '../../../kit/icons/YouTubeIcon';
import { IncIcon } from '../../../kit/icons/IncIcon';
import { ForbesIcon } from '../../../kit/icons/ForbesIcon';
import { YM_LEAD } from '../../../helpers/yandexMetrika';

export const mainLeadData = {
  question: {
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
    leadPage: 'Главная',
    program: 'JS',
  },
  materials: {
    leadAction: 'Подписка гайд',
    leadBlock: 'Программы',
    leadPage: 'Главная',
    eventName: 'Материалы для подготовки',
  },
};

const data = {
  seo: {
    title:
      'Обучение IT с трудоустройством ☝ | Школа программирования Эльбрус Буткемп',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Получите практические навыки и станьте востребованным специалистом в IT вместе со школой программирования Эльбрус Буткемп! ✅ обучающие курсы по веб-разработке и Data Science',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Обучение IT с трудоустройством ☝ | Школа программирования Эльбрус Буткемп',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Получите практические навыки и станьте востребованным специалистом в IT вместе со школой программирования Эльбрус Буткемп! ✅ обучающие курсы по веб-разработке и Data Science',
      },
      {
        property: 'og:image',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/02.JPG',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: 'https://twitter.com/elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/02.JPG',
      },
    ],
  },
  intro: {
    statistic: [
      {
        id: 8,
        value: '--',
        description: 'средняя зарплата после обучения',
        link: null,
      },
      {
        id: 2,
        value: '--',
        description: 'студентов находят работу..',
        link: {
          title: 'исследование hh.ru',
          url: '/job-report2023/',
        },
      },
    ],
  },
  programs: {
    title: 'Программы обучения',
    subtitle: 'наши',
    data: {
      list: [
        {
          program: 'JS',
          link: '/web-development/',
          formats: ['офлайн в Москве и Санкт-Петербурге', 'онлайн'],
          entranceInterview: 'Есть вступительное интервью',
          ymSubmitEventType: 'MainWebDevProgram',
          ymSubmitExtraEventType: YM_LEAD,
          courseType: 'Основная программа',
          location: 'Онлайн',
          name: 'Веб-разработчик',
          length: ['3-6 месяцев'],
          features: ['Учеба из любой точки мира', 'Занятия в реальном времени'],
          getMaterials: true,
          tags: ['онлайн'],
        },
        // {
        //   program: 'JS',
        //   ymSubmitEventType: 'mainMaterialsOnlineJs',
        //   ymSubmitExtraEventType: YM_LEAD,
        //   courseType: 'Основная программа',
        //   location: 'Онлайн',
        //   name: 'JavaScript Part-time',
        //   length: ['24 недели', '600 часов'],
        //   features: [
        //     'Учеба из любой точки мира',
        //     'Занятия по вечерам и выходным',
        //   ],
        //   getMaterials: true,
        //   link: 'https://elbrusbootcamp.online/parttime-online-js?utm_source=elbrus&utm_medium=programms&utm_campaign=parttime',
        //   tags: ['part-time JS'],
        //   partTime: true,
        // },
        // {
        //   program: 'JS',
        //   ymSubmitEventType: 'mainMaterialsJsMsk',
        //   ymSubmitExtraEventType: YM_LEAD,
        //   courseType: 'Основная программа',
        //   location: 'Москва',
        //   name: 'Fullstack JavaScript',
        //   length: ['12 недель', '500 часов'],
        //   features: [
        //     'Интенсивное погружение в разработку',
        //     'Помощь в трудоустройстве',
        //   ],
        //   getMaterials: true,
        //   link: '/msk-js/',
        //   tags: ['мск'],
        // },
        // {
        //   program: 'JS',
        //   ymSubmitEventType: 'mainMaterialsJsSpb',
        //   ymSubmitExtraEventType: YM_LEAD,
        //   courseType: 'Основная программа',
        //   location: 'Санкт-Петербург',
        //   name: 'Fullstack JavaScript',
        //   length: ['12 недель', '500 часов'],
        //   features: [
        //     'Интенсивное погружение в разработку',
        //     'Помощь в трудоустройстве',
        //   ],
        //   getMaterials: true,
        //   link: '/spb-js/',
        //   tags: ['спб'],
        // },
        {
          program: 'DS',
          formats: ['онлайн'],
          entranceInterview: 'Есть вступительное интервью',
          link: '/datascience/',
          ymSubmitEventType: 'MainDSProgram',
          ymSubmitExtraEventType: YM_LEAD,
          courseType: 'Основная программа',
          location: 'Онлайн',
          name: 'Data Science',
          length: ['4 месяца'],
          features: [
            'Адаптированная программа',
            'Погружение в профессию Data Scientist',
          ],
          getMaterials: true,
          tags: ['дс онлайн'],
        },
        // {
        //   ymSubmitEventType: null,
        //   courseType: 'Подготовительная программа',
        //   program: 'PREPARE_JS',
        //   location: 'Онлайн',
        //   name: 'Пре-курс JavaScript',
        //   length: ['2 недели', '20 часов'],
        //   features: [
        //     'Первые шаги в JavaScript',
        //     'Подготовка к вступительному интервью',
        //   ],
        //   link: '/js-intense/',
        // },
        // {
        //   ymSubmitEventType: null,
        //   courseType: 'Подготовительная программа',
        //   program: 'PREPARE_DS',
        //   location: 'Онлайн',
        //   name: 'Пре-курс  Data Science',
        //   length: ['2 недели', '20 часов'],
        //   features: ['Знакомство с Data Science', '8 практических занятий'],
        //   link: '/ds-intense/',
        // },
        // {
        //   ymSubmitEventType: null,
        //   courseType: 'Бесплатный курс',
        //   program: 'FREE_JS',
        //   location: 'Онлайн',
        //   name: 'Основы Javascript',
        //   length: [null, '20 часов'],
        //   features: [
        //     'Старт в любое время',
        //     'Тренажер с практикой и тестами',
        //     'Синтаксис языка, типы данных, функции и циклы',
        //   ],
        //   link: 'https://elbrusbootcamp.online/free-course',
        // },
        {
          program: 'UXUI',
          link: 'https://opendays.elbrusboot.camp/ux-ui-design/',
          formats: ['онлайн'],
          entranceInterview: 'Есть вступительное интервью',
          ymSubmitEventType: 'MainDesignProgram',
          ymSubmitExtraEventType: YM_LEAD,
          courseType: 'Основная программа',
          location: 'Онлайн',
          name: 'UX/UI дизайн',
          length: ['3 месяца'],
          features: ['Учеба из любой точки мира', 'Занятия в реальном времени'],
          getMaterials: true,
          tags: ['онлайн'],
        },
      ],
    },
  },
  events: {
    title: 'Наши события',
    subtitle: 'Ближайшие',
    ymEvents: {
      DOD_JS: 'MainEventJS',
      DOD_DS: 'MainEventDS',
      ymEventClick: 'MainEventAll',
    },
    cardBullits: {
      online: [
        'Встреча с выпускником',
        'Особенности обучения онлайн',
        'Подробная программа обучения',
      ],
      offline: [
        'Встреча с выпускником',
        'Экскурсия по кампусу',
        'Подробная программа обучения',
      ],
      design: [
        'Погружение в сферу UX/UI',
        'Общение с менторами курса',
        'Вопрос-ответ про обучение',
      ],
    },
  },
  description: {
    title: 'Что такое буткемп?',
    subtitle: 'Формат',
    data: {
      title:
        'Это интенсивный формат обучения программированию (ежедневно, с утра до вечера, на протяжении трех месяцев в кампусе и четырех при формате онлайн) на реальных проектах с трудоустройством после обучения',
      subtitle:
        'В интервью с ИТ-Бородой рассказываем о формате образования в школе программирования Эльбрус Буткемп',
      video:
        'https://vk.com/video_ext.php?oid=-166848737&id=456239968&hd=2&autoplay=1',
      description: '1 час 12 минут',
      myTargetEvent: 'MainVideoInterview',
    },
  },
  employment: {
    title: 'Трудоустройство',
    subtitle: 'Быстрое',
    data: {
      statistic: [
        {
          id: 1,
          value: '--',
          description: 'студентов выпущено...',
          link: null,
        },
        {
          id: 2,
          value: '--',
          description: 'студентов находят работу..',
          link: null,
        },
        {
          id: 3,
          value: '--',
          description: 'средняя зарплата после обучения',
          link: null,
        },
        {
          id: 5,
          value: '--',
          description: null,
          link: {
            title: 'Рейтинг Яндекса',
            url: 'https://yandex.ru/search/?lr=213&text=elbrus+bootcamp',
          },
        },
      ],
      research: {
        title: 'Исследование hh.ru',
        content:
          'Статистика по трудоустройству выпускников Эльбрус Буткемп подтверждена исследователями hh.ru',
        link: {
          title: 'Читать исследование',
          url: '/job-report2023/',
        },
      },
    },
  },
  work: {
    data: {
      companies: {
        title: 'Где работают наши выпускники',
      },
      steps: {
        title: 'Содействие в трудоустройстве',
        list: [
          {
            title: 'создаем стратегию поиска работы',
            description:
              'Устанавливаем цели по поиску работы. Работаем с источниками вакансий.',
          },
          {
            title: 'помогаем создать резюме',
            description:
              'Составляем резюме и сопроводительное письмо, оформляем проекты на GitHub.',
          },
          {
            title: 'Готовим к собеседованию',
            description:
              'Прорабатываем вопросы, имитируя реальное будущее собеседование с HR и техническое интервью.',
          },
          {
            title: 'Передаем опыт выпускников',
            description:
              'Общение с выпускниками помогает понять формат трудоустройства и необходимых знаний на реальном примере.',
          },
          {
            title: 'Проводим Тренинги',
            description:
              'Прохождение тренингов помогает сформировать софт скиллы студента и подготовить его к новой карьере. Прорабатываем синдром самозванца.',
          },
          {
            title: 'Сопровождаем\nв течение года',
            description:
              'Карьерный коуч остается на связи в течение целого года после обучения и всегда готов поддержать.',
            icon: '🤝',
          },
        ],
      },
      link: {
        title: 'О трудоустройстве выпускников',
        url: '/career/',
      },
    },
  },
  graduates: {
    title: 'Выпускники',
    subtitle: 'наши',
    data: {
      statistic: [
        {
          id: 1,
          value: '--',
          description: 'студентов выпущено...',
          link: null,
        },
        {
          id: 2,
          value: '93%',
          description: 'студентов находят работу за 3 месяца',
          link: null,
        },
        {
          id: 5,
          value: '5/5',
          description: null,
          link: {
            title: 'Рейтинг Яндекса',
            url: 'https://yandex.ru/search/?lr=213&text=elbrus+bootcamp',
          },
        },
      ],
      people: [
        {
          photo: 'solar',
          fullName: 'Дарья Соляр',
          position: 'Разработчик',
          company: {
            logo: 'sber',
          },
          content:
            'Больше всего запомнилась командная атмосфера всего буткемпа - это помогло освоить огромный объём информации, которой оказалось достаточно для легкого входа в IT. Я благодарна всему преподавательскому составу за полную поддержку в обучении и не жалею, что обучалась в Эльбрус Буткемп.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/sdaryae',
            },
          ],
        },
        {
          photo: 'kotov',
          fullName: 'Дмитрий Котов',
          position: 'Разработчик',
          company: {
            logo: 'sravni',
          },
          content:
            'Было больно, сложно, но это того стоило на 1000%. Невероятная атмосфера, где ты с головой погружаешь в программирование и командную работу и выходишь специалистом с уверенными знаниями, а самое главное с пониманием, что ты сможешь освоить новую технологию/язык/библиотеку за очень сжатые сроки.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/kotov267',
            },
          ],
        },
        {
          photo: 'rebrova',
          fullName: 'Юлия Реброва',
          position: 'Data Scientist',
          company: {
            logo: 'moex',
          },
          content:
            'С новой профессией начала любить понедельники. До сих пор не понимаю, как это мне платят за то, что я играюсь с модельками.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/re8rova',
            },
          ],
        },
        {
          photo: 'kozin',
          fullName: 'Алексей Козин',
          position: 'Разработчик',
          company: {
            logo: 'mtsdigital',
          },
          content:
            'Это было самое сложное обучение в моей жизни, но оно того стоило. Благодаря программе Эльбруса и моим стараниям получилось изменить жизнь. Перестал ненавидеть понедельники и больше не чувствую себя на работе занимаясь программированием.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/askozin',
            },
          ],
        },
        {
          photo: 'kirshanov',
          fullName: 'Дмитрий Киршанов',
          position: 'Фронтенд-лид',
          company: {
            logo: 'griddynamics',
          },
          content:
            'Эльбрус дал мне не только много полезных знаний, которые я по сей день ежедневно применяю в работе, но и самое главное в профессии разработчика - умение быстро учиться новому и уверенность в себе.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/kirshach',
            },
          ],
        },
        {
          photo: 'anisina',
          fullName: 'Александра Анисина',
          position: 'Разработчик',
          company: {
            logo: 'x5group',
          },
          content:
            'Эльбрус с начала учебы научил, что в работе/учебе должна быть вовлеченность 1000%. Порций мотивационных историй от приглашенных работающих выпускников Эльбруса хватило на все 20 проваленных собеседований, чтоб пройти следующие несколько успешно и получить оффер.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/aleksandra_anisina',
            },
          ],
        },
        {
          photo: 'obremok',
          fullName: 'Александра Оберемок',
          position: 'Data Scientist',
          company: {
            logo: 'samokat',
          },
          content:
            'Буткемп — это классная глава вашей жизни. После выпуска вы уже не будете прежними!',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/AlexandraOberemok',
            },
          ],
        },
        {
          photo: 'milko',
          fullName: 'Владислав Милько',
          position: 'Разработчик',
          company: {
            logo: 'sberkorus',
          },
          content:
            'Здесь люди разрушают мифы. 3 месяца и ты готовая боевая единица, способная решать реальные бизнес задачи. Лучший формат обучения на рынке СНГ!',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/vladmilko',
            },
          ],
        },
        {
          photo: 'krivoshein',
          fullName: 'Вадим Кривошеин',
          position: 'Разработчик',
          company: {
            logo: 'plata',
          },
          content:
            'Мне повезло решиться сменить профессию, повезло c обучением в Эльбрусе, повезло поработать в крутых командах МТС и OZON. Могу пожелать, чтобы вам повезло еще больше в IT сфере.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/vadim_nvrsk',
            },
          ],
        },
        {
          photo: 'gluhova',
          fullName: 'Юлия Глухова',
          position: 'Fullstack developer',
          company: {
            logo: 'web3tech',
          },
          content:
            'До Эльбруса год самостоятельно изучала js, но знания не складывались в общую картину. Благодаря структурированной программе и компетентным преподавателям удалось восполнить пробелы. После карьерной недели за 3 недели получила 10 офферов и вышла на работу с хорошей зарплатой middle-разработчика.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/juljuliks',
            },
          ],
        },
        {
          photo: 'pyshkin',
          fullName: 'Вячеслав Пышкин',
          position: 'Разработчик',
          company: {
            logo: 'comagic',
          },
          content:
            'Выверенное сочетание теории и практики, работа над интересными проектами по процессам современных компаний, опытные преподаватели, карьерная поддержка — всё это есть в Эльбрусе. Даже с нулевыми знаниями на старте за 3 месяца реально получить необходимый набор знаний для своей первой работы в IT.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/slava_push',
            },
          ],
        },
        {
          photo: 'panina',
          fullName: 'Дарья Панина',
          position: 'Data Scientist',
          company: {
            logo: 'rete',
          },
          content:
            'Больше всего в процессе обучения мне запомнились потрясающий уровень поддержки со стороны одногруппников и сама атмосфера, которую транслирует Эльбрус: учиться круто, ошибаться не страшно, надо все пробовать!',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/panina_da',
            },
          ],
        },
        {
          photo: 'klychnikov',
          fullName: 'Сергей Клычников',
          position: 'Frontend-разработчик',
          company: {
            logo: 'protek',
          },
          content:
            'Давно хотел сменить род деятельности, но не понимал, с чего начать и где взять мотивацию. Друг из Эльбруса присылал мне мини-отчеты о том, что прошел за неделю. Потом решил пойти сам, было сложно, но классно. Сейчас чувствую себя более свободным и востребованным.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/SergeyKlychnikov',
            },
          ],
        },
        {
          photo: 'dibceva',
          fullName: 'Ольга Дибцева',
          position: 'Разработчик',
          company: {
            logo: 'sber',
          },
          content:
            'Отличная атмосфера, мотивирующая развиваться и не сдаваться перед трудностями. Практические задания, приближенные к рабочим задачам, и, конечно, крутые преподаватели, которые всегда были рядом. Все это позволило быстро получить отличный оффер.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/OlgaDibts',
            },
          ],
        },
        {
          photo: 'abdulvasiev',
          fullName: 'Муминшо Абдулвасиев',
          position: 'Аналитик',
          company: {
            logo: 'presidentacademy',
          },
          content:
            'Уютная атмосфера, крутые и открытые преподаватели, классное комьюнити, поддержка отовсюду и очень много практики - вот, чем мне запомнился Эльбрус. Будет сложно, но нет ничего невозможного. Если получилось у меня, то и у тебя обязательно получится!',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/RealMumba',
            },
          ],
        },
        {
          photo: 'nu',
          fullName: 'Руслан Ню',
          position: 'UI TeamLead',
          company: {
            logo: 'x5tech',
          },
          content:
            'Если тебе тяжело и сложно, значит, ты на верном пути – развиваешься и растёшь. Если же стало слишком легко, вероятно, ты делаешь что-то неправильно или стоишь на месте!',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/ruslan_niu',
            },
          ],
        },
        {
          photo: 'yakshin',
          fullName: 'Юрий Якшин',
          position: 'Разработчик',
          company: {
            logo: 'bft',
          },
          content:
            'Состав успеха по моему мнению: 15% вера в себя, 15% удачи и 70% ежедневной работы, нацеленной на достижение цели. Делайте усилие над собой, когда вам лень, или что-то не получается. Повышайте свой скилл практикой, тогда вы добьетесь того, чего хотели.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/Yakshin00',
            },
          ],
        },
        {
          photo: 'gamayun',
          fullName: 'Илья Гамаюн',
          position: 'Разработчик',
          company: {
            logo: 'sushi',
          },
          content: 'Возможности не приходят сами — вы создаете их',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/ilyagamayun',
            },
          ],
        },
        {
          photo: 'tsoy',
          fullName: 'Юлия Цой',
          position: 'Разработчик',
          company: {
            logo: 'broex',
          },
          content:
            'Довольна сменой профессии на 100%. Не думаю, что смогла бы самостоятельно сразу устроиться на те условия, на которых в данный момент работаю',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/l1lyjul',
            },
          ],
        },
        {
          photo: 'hvan',
          fullName: 'Владимир Хван',
          position: 'Разработчик',
          company: {
            logo: 'wildberries',
          },
          content:
            'Четкая структура курса, преподаватели из IT-компаний, обучение очень насыщенное, все заточено под получение знаний и навыков для выполнения реальных задач и устройства на работу.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/Beshunmo',
            },
          ],
        },
        {
          photo: 'loss',
          fullName: 'Филипп Лосс',
          position: 'Разработчик',
          company: {
            logo: 'strelka',
          },
          content:
            'Эльбрус выгодно отличается от других школ. Интенсивная программа с 90% практики и 10% теории, профессиональные и отзывчивые преподаватели, комфортные классы, регулярно приглашаются интересные спикеры.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/filipploss',
            },
          ],
        },
        {
          photo: 'krolikov',
          fullName: 'Андрей Кроликов',
          position: 'Разработчик',
          company: {
            logo: 'qiwi',
          },
          content:
            'Я даже не думал, что за 11 недель из меня выйдет web-разработчик, но у этих ребят получилось. Атмосфера, люди, да и сам формат максимально способствуют твоему обучению и профессиональному росту, как разработчика. Посоветую даже своей маме.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/krolikovandrey',
            },
          ],
        },
        {
          photo: 'topal',
          fullName: 'Юлиан Топал',
          position: 'Разработчик',
          company: {
            logo: 'glowbyte',
          },
          content:
            'Прекрасное место, где вы можете стать младшим разработчиком и улучшить свои навыки в программировании. Здесь вы можете быть уверены, что у вас всегда будут хорошие условия в офисе, лучшая программа обучения.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/iti92',
            },
          ],
        },

        {
          photo: 'kucher',
          fullName: 'Александр Кучер',
          position: 'Разработчик',
          company: {
            logo: 'quantorium',
          },
          content:
            'Обучение в Эльбрус очень интенсивное и сложное, но та атмосфера и поддержка, которая окружает студентов в кампусе, помогает быстрее усваивать всю информацию, которую дают преподаватели. Карьерная поддержка после',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/kucher83',
            },
          ],
        },
      ],
    },
  },
  news: {
    title: 'СМИ о нас и буткемпах',
    subtitle: 'публикации',
    data: {
      list: [
        {
          IconComponent: TipIcon,
          isMobileHidden: false,
          url: 'https://theoryandpractice.ru/posts/17581-v-programmisty-za-polgoda-chto-takoe-butkempy-i-kak-osvoit-koding-v-rekordnye-sroki',
        },
        {
          IconComponent: CehIcon,
          isMobileHidden: false,
          url: 'https://zeh.media/praktika/it/6084271-kak-vybrat-butkemp',
        },
        {
          IconComponent: VedomostiIcon,
          isMobileHidden: false,
          url: 'https://www.vedomosti.ru/management/articles/2019/10/02/812701-biologi-yuristi-moryaki',
        },
        {
          IconComponent: TprogerIcon,
          isMobileHidden: false,
          url: 'https://tproger.ru/events/elbrus-coding-bootcamp/',
        },
        {
          IconComponent: VcIcon,
          isMobileHidden: false,
          url: 'https://vc.ru/hr/65253-kem-ty-hochesh-stat-kogda-ispolnitsya-30',
        },
        {
          IconComponent: SchoolIcon,
          isMobileHidden: false,
          url: 'https://ioe.hse.ru/stand_up_and_fight',
        },
        {
          IconComponent: RusbaseIcon,
          isMobileHidden: false,
          url: 'https://rb.ru/opinion/bootcamp-v-rf/',
        },
        {
          IconComponent: YouTubeIcon,
          isMobileHidden: false,
          url: 'https://www.youtube.com/watch?v=2pQ1vp0mcXQ',
        },
        {
          IconComponent: IncIcon,
          isMobileHidden: false,
          url: 'https://incrussia.ru/concoct/elbrus/',
        },
        {
          IconComponent: ForbesIcon,
          isMobileHidden: true,
          url: 'https://www.forbes.ru/svoi-biznes/465527-takoj-skacok-my-vidim-vpervye-pocemu-v-rossii-vyros-spros-na-it-kursy',
        },
      ],
    },
  },
  faq: {
    subtitle: 'Ответы',
    title: 'F.A.Q.',
    data: {
      content:
        'Основатель буткемпа Георгий Бабаян разбирает ваши самые популярные вопросы.',
      list: [
        'Что такое буткемп?',
        'Каким направлениям мы обучаем?',
        'Каков принцип обучения в буткемпе? Почему это так эффективно?',
        'Кто преподает в Эльбрус Буткемп?',
        'Легко ли найти работу после обучения в Эльбрус Буткемп?',
        'Как поступить на курс?',
        'Почему нас выбирают?',
      ],
      link: {
        title: 'Страница ответов на другие вопросы',
        url: '/faq/',
      },
      video:
        'https://vk.com/video_ext.php?oid=-166848737&id=456240036&hd=1&autoplay=1',
      description: '4 минуты',
    },
  },
  projects: {
    title: 'Реальные проекты',
    subtitle: 'В Вашем портфолио',
    data: {
      description:
        'Проекты в Эльбрус Буткемп — не симуляция настоящей работы, а заказы от компаний-партнеров, которые приглашают лучших студентов для дальнейшего сотрудничества. В процессе обучения  вы разработаете 3 проекта, которые станут частью вашего профессионального портфолио:',
      points: [
        'Проект от заказчика',
        'Индивидуальный проект',
        'Выпускной проект',
      ],
      list: [
        {
          description: 'GymLog — дневник тренировок',
          preview: 'gym',
          video: 'https://www.youtube.com/embed/gx577KVB2v0',
        },
        {
          description: 'Манга — сервис для перевода японских комиксов',
          preview: 'manga',
          video: 'https://www.youtube.com/embed/RR3h-KIO8Hw',
        },
        {
          description:
            'Price-Tracker — сервис для отслеживания цен на выбранные товары',
          preview: 'pricetracker',
          video: 'https://www.youtube.com/embed/lB7N70xP_DQ',
        },
        {
          description: 'All-ffers — агрегатор объявлений для IT-фрилансеров',
          preview: 'allffers',
          video: 'https://www.youtube.com/embed/kP7XdQJUzNE',
        },
        {
          description: 'Монополька — онлайн игра в Монополию',
          preview: 'monopolka',
          video: 'https://www.youtube.com/embed/__ZiiXDp_Cs',
        },
      ],
    },
  },
  payment: {
    title: 'Стоимость обучения',
    subtitle: 'let price',
    data: {
      description: [
        {
          content: 'Окупаемость обучения уже на 3-й месяц. Данные',
          link: {
            title: 'исследования hh.ru',
            url: '/job-report2023/',
          },
        },
        {
          content: [
            'Главная цель — трудоустройство.',
            '86% студентов находят работу в течение 3 месяцев',
          ],
        },
      ],
      options: [
        {
          title: 'Помесячно',
          list: [
            {
              content: null,
              label: null,
              icon: 'calendar',
            },
          ],
        },
        {
          title: 'Сразу',
          list: [
            {
              content: 'Скидка при оплате сразу',
              icon: 'discount',
            },
            {
              content: 'Возможность забронировать место по предоплате',
              icon: 'payment',
            },
          ],
        },
      ],
    },
  },
  questions: {
    message: 'Готовы ответить на ваши вопросы',
    actions: {
      first: {
        title: 'Оставить заявку',
      },
    },
    ymEvents: {
      mainProgrammsBottomClick: 'mainProgrammsBottom',
      consultationFormSubmit: 'mainConsultation',
    },
  },
  ymEvents: {
    mainProgramsTopClick: 'mainProgramsTop',
    mainFaqClick: 'mainFaq',
  },
};

export default data;
